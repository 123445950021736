import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import image01 from "assets/img/canhappy/五叶花健康-01.svg";
import image02 from "assets/img/canhappy/五叶花健康-02.svg";
import image03 from "assets/img/canhappy/五叶花健康-03.svg";
import image04 from "assets/img/canhappy/五叶花健康-04.svg";
import image05 from "assets/img/canhappy/五叶花健康-05.svg";
import image06 from "assets/img/canhappy/五叶花健康-06.svg";
import image07 from "assets/img/canhappy/五叶花健康-07.svg";
import image08 from "assets/img/canhappy/五叶花健康-08.svg";
import image09 from "assets/img/canhappy/五叶花健康-09.svg";
import image10 from "assets/img/canhappy/五叶花健康-10.svg";
import image11 from "assets/img/canhappy/五叶花健康-11.svg";
import image12 from "assets/img/canhappy/五叶花健康-12.svg";
import image13 from "assets/img/canhappy/五叶花健康-13.svg";
import image14 from "assets/img/canhappy/五叶花健康-14.svg";
import image15 from "assets/img/canhappy/五叶花健康-15.svg";
import image16 from "assets/img/canhappy/五叶花健康-16.svg";
import image17 from "assets/img/canhappy/五叶花健康-17.svg";
import image18 from "assets/img/canhappy/五叶花健康-18.svg";
import image19 from "assets/img/canhappy/五叶花健康-19.svg";
import image20 from "assets/img/canhappy/五叶花健康-20.svg";
import image21 from "assets/img/canhappy/五叶花健康-21.svg";
import image22 from "assets/img/canhappy/五叶花健康-22.svg";
import image23 from "assets/img/canhappy/五叶花健康-23.svg";
import image24 from "assets/img/canhappy/五叶花健康-24.svg";
import loading from "assets/img/loading.jpg";

const imgs = {
	"image01":image01,
	"image02":image02,
	"image03":image03,
	"image04":image04,
	"image05":image05,
	"image06":image06,
	"image07":image07,
	"image08":image08,
	"image09":image09,
	"image10":image10,
	"image11":image11,
	"image12":image12,
	"image13":image13,
	"image14":image14,
	"image15":image15,
	"image16":image16,
	"image17":image17,
	"image18":image18,
	"image19":image19,
	"image20":image20,
	"image21":image21,
	"image22":image22,
	"image23":image23,
	"image24":image24
};

const checkScrollHeightAndLoadAnimation = () => {
  const windowHeight = window.innerHeight;
  let Elements = document.getElementsByTagName("img");
  let len = Elements.length;
  for (var i = 0; len > i; i++) {
    var parentOffsetTop = Elements[i].offsetTop;
    if (window.pageYOffset + windowHeight > parentOffsetTop + 200) {
			if(Elements[i].attributes['qe']){
				Elements[i].src = imgs[Elements[i].attributes['qe'].value];
				Elements[i].removeAttribute('qe');
			}
    }
  }
}
setInterval(
  checkScrollHeightAndLoadAnimation, 1000
)

export default function SectionWeb() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <img qe="image03"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image02"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image04"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image05"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image06"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image22"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image23"	style={{ width: "100%" }} src={loading} alt="" />
        <img qe="image24"	style={{ width: "100%" }} src={loading} alt="" />
      </div>
    </div>
  );
}
