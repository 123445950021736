import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import logoImg from "assets/img/logo.png";
import { Link } from 'react-router-dom'

import aiBot from 'assets/json/ai_bot.json';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
	const bots = [];
	for(var o in aiBot){
		bots.push({type:aiBot[o]["type"],title:o,children:aiBot[o]["children"]});
	}
	console.log(bots);
  return (
    <div>
      <Header brand={<img alt="五叶花健康" style={{ width: "150px" }} src={logoImg} />} rightLinks={<HeaderLinks />} fixed color="transparent" changeColorOnScroll={{ height: 400, color: "white" }} {...rest} />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} style={{height:"200px"}}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>

							{bots.map((val, key) => {
									if(1==val.type){
										return (
											<div style={{paddingTop:"30px"}}>
												<div style={{color:"#000000",fontSize:"40px"}}> {val.title} </div>
												<GridContainer justify="left">
													{val.children.map((val1, key1) => { return(
														<GridItem xs={12} sm={12} md={3} style={{paddingTop:"30px"}}> 
															<Button variant="contained" style={{justifyContent:"left",height:"60px",width:"100%",fontSize:"16px",margin:"5px",backgroundColor:"#2196f388"}} component={Link} onClick={() => { window.location.href = val1.url; }}>
																<img src={val1["img"]} style={{width:"40px",height:"40px"}}/>
																{val1.name}
															</Button> 
															<div style={{color:"#000000"}}>{val1.desc}</div>
														</GridItem>
													) })}
												</GridContainer>
											</div>
										);
									}else{
									}
								})}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
