import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/christian.jpg";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import logoImg from "assets/img/logo.png";

import axios from 'axios';

const useStyles = makeStyles(styles);

const TagSet = {
	TagName: "",
	TagDesc: "",
	TagDescImage: [],
	TagDescImageUrl: "",
	TagToUUID: "",
	TagDuration: "",
	TagBaseinfo: 0,
	TagBingMing: {},
	TagBianZhengDian: {},
	TagChuFang: {},
	TagJiaJianHuaCai: {},
	TagLiaoCheng: 5,
	TagLiaoChengYaoLiang: 5,
	TagTiaoLi: {},
	TagJianZhuYaoLing: "",
	TagFuYongZhiNan: "",
	TagFuYongFanYing: "",
	TagBeiXuanFangAn: "",
	TagYuHouZhuTuo: ""
};
const TagName = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

	const handleSubmit = (event) => {
		// 定义一个提交表单的事件处理函数，用于发送POST请求
		event.preventDefault(); // 阻止默认行为，即刷新页面
		axios.get('assets/json/ccz.txt', {}).then(response => {
			console.log(response); // 打印响应信息
		}).catch(error => {
			console.log(error); // 打印错误信息
		});
	};

  return (
    <div>
      <Header brand={<img alt="五叶花健康" style={{ width: "150px" }} src={logoImg} />} rightLinks={<HeaderLinks />} fixed color="transparent" changeColorOnScroll={{ height: 400, color: "white" }} {...rest} />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} style={{height:"200px"}}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} style={{marginTop:"50px"}}>
								<img src="http://www.canhappy.com/common/images/张锡纯.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/任应秋.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/陈潮祖.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/李源.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/黄煌.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/JT叔叔.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/黄成义.jpeg" style={{height:"100px",margin:"10px"}}/>
								<img src="http://www.canhappy.com/common/images/张至顺.jpeg" style={{height:"100px",margin:"10px"}}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{marginTop:"50px"}}>
								<Button variant="contained" style={{height:"100px",width:"100%",fontSize:"32px",margin:"20px",backgroundColor:"#2196f388"}} onClick={handleSubmit}> 获取 </Button>
								<Autocomplete inputValue="肺系->表卫失调->寒邪束表" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输病机，多个病机用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="辛温解表" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输治法，多个治法用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输病因，多个病因用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="(恶寒&&发热&&无汗)||头痛||身疼||喷嚏不停||鼻塞||鼻流清涕||咽痛||失音||咳嗽||气喘||白稀痰||呕吐||腹泻||腹痛||小便不利" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输主症，多个主症用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="((舌质淡&&舌苔白)&&(脉象浮紧||脉象浮缓)" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输辨证依据，多个辨证依据用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="麻黄10g、桂枝6g、杏仁9g、甘草6g、生石膏30g、生姜9g、大枣15g" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输方剂，多味本草用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="防风通圣丸" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输需要的中草药，多个中草药用顿号分开。" variant="outlined" />} />

              </GridItem>

              <GridItem xs={12} sm={12} md={12} style={{marginTop:"50px"}}>

								<Autocomplete inputValue="更年期综合症" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输入标签名" variant="outlined" />} />

								<Autocomplete inputValue="头痛" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输病名，多个病名用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="平素蕴热，复感外邪。" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输病因，多个病因用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="表寒里热" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输病机，多个病机用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="发表清里法" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输治法，多个治法用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="(恶寒重&&发热重&&无汗&&烦躁&&头痛&&身疼)||口渴" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输主症，多个主症用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="脉象浮紧&&(恶寒重&&发热重&&无汗&&烦躁)" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输辨证依据，多个辨证依据用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="麻黄10g、桂枝6g、杏仁9g、甘草6g、生石膏30g、生姜9g、大枣15g" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输方剂，多味本草用顿号分开。" variant="outlined" />} />

								<Autocomplete inputValue="防风通圣丸" id="combo-box-demo" options={TagName} getOptionLabel={(option) => option.title} style={{ width: "90%" ,margin:"20px"}} renderInput={(params) => 
								<TextField {...params} label="请输需要的中草药，多个中草药用顿号分开。" variant="outlined" />} />

              </GridItem>

            </GridContainer>
            <div className={classes.description}>
              <p>
                An artist of considerable range, Chet Faker — the name taken by
                Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs
                and records all of his own music, giving it a warm, intimate
                feel with a solid groove structure.{" "}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Studio",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Work",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Favorite",
                      tabIcon: Favorite,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
