import React from "react";
// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Box from '@material-ui/core/Box';
import p1 from "assets/img/logo1.png";

export default function SectionBizBlock() {
  return (
    <Box>
			<center><img style={{ width: "300px" }} src={p1} alt="" /></center>
    </Box>
  );
}
