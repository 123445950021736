import React from "react";
// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Box from '@material-ui/core/Box';

export default function SectionBizBlock() {
  return (
    <Box>
      <GridContainer justify="center" style={{ padding: "0", margin: "0", opacity: 1 }}>
        <GridItem xs={12} sm={12} style={{ padding: 0, margin: 0 }}>
          <h2><strong>为人子女者不知医，谓不孝；为人父母者不知医，谓不慈；</strong></h2>
				  <h4 style={{paddingTop:"30px"}}>“——出自《为政篇第26章》”</h4>
        </GridItem>
      </GridContainer >
    </Box>
  );
}
