import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";


import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";
import logoImg from "assets/img/logo.png";
import img1 from "assets/img/eco_0.png";
import img2 from "assets/img/eco_1.jpeg";
import img3 from "assets/img/eco_3.jpeg";

import img4 from "assets/img/edu_0.jpeg";
import img5 from "assets/img/edu_1.jpg";

import ButtonBase from '@material-ui/core/ButtonBase';
import { Link } from 'react-router-dom'

import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(styles);

export default function LoginPage(props) {
	const history = useHistory();
  const gotoPage1 = () => { 
    history.push('/landing-page');
	};
  const gotoPage2 = () => { 
    history.push('/profile-page');
	};
  const gotoPage3 = () => { window.location.href = 'https://www.canhappy.com/vhost/wxbbec7b6d4f24790f/seller.php?s=/Public/login'; };
  const gotoPage4 = () => { window.location.href = 'https://www.canhappy.com/vhost/wxbbec7b6d4f24790f/seller.php?s=/Supply/login'; };
  const gotoPage5 = () => { window.location.href = 'https://daojia.canhappy.com'; };
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
	//<GridItem xs={12} sm={12} md={4}> <Button variant="contained" style={{height:"150px",width:"100%",fontSize:"32px",margin:"20px",backgroundColor:"#2196f388"}} component={Link} onClick={gotoPage1}> AI问诊管理 </Button> </GridItem>
	//<GridItem xs={12} sm={12} md={4}> <Button variant="contained" style={{height:"150px",width:"100%",fontSize:"32px",margin:"20px",backgroundColor:"#2196f388"}} component={Link} onClick={gotoPage2}> 研习内容管理 </Button> </GridItem>
  return (
    <div>
      <Header
        brand={<img alt="五叶花健康" style={{ width: "150px" }} src={logoImg} />}
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}> <Button variant="contained" style={{height:"150px",width:"100%",fontSize:"32px",margin:"20px",backgroundColor:"#2196f388"}} component={Link} onClick={gotoPage3}> 团购管理后台 </Button> </GridItem>
            <GridItem xs={12} sm={12} md={4}> <Button variant="contained" style={{height:"150px",width:"100%",fontSize:"32px",margin:"20px",backgroundColor:"#2196f388"}} component={Link} onClick={gotoPage4}> 团购供应商 </Button> </GridItem>
            <GridItem xs={12} sm={12} md={4}> <Button variant="contained" style={{height:"150px",width:"100%",fontSize:"32px",margin:"20px",backgroundColor:"#2196f388"}} component={Link} onClick={gotoPage5}> 到家服务后台 </Button> </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
